<template>
  <div>
    <VueTitle title="Gallerie"></VueTitle>
    <div class="container">
      <ProgressiveImage
        class="image"
        loading="lazy"
        v-for="image in images"
        :key="image"
        :src="require('@/assets/gallery/preview/' + image)"
        :alt="image"
      />
    </div>
  </div>
</template>

<script>
import VueTitle from "@/components/VueTitle";
import { ProgressiveImage } from "vue-progressive-image";

export default {
  name: "Gallery",
  components: { VueTitle, ProgressiveImage },
  data() {
    return {
      images: [],
    };
  },
  methods: {},
  beforeMount() {
    const files = require.context("@/assets/gallery/preview", false, /\.jpeg$/);
    files.keys().forEach((file) => {
      console.log(file);
      file = file.substring(2);
      this.images.push(file);
    });
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.image {
  object-fit: contain;
  height: fit-content;
  margin: 25px;
}
</style>
